.w100 {
  width: 100%;
}

.flexH {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flexV {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.flexMainStart {
  justify-content: flex-start;
}

.flexMainEnd {
  justify-content: flex-end;
}

.flexMainBetween {
  justify-content: space-between;
}

.flexCrossStart {
  align-items: flex-start;
}

.flexCrossEnd {
  align-items: flex-end;
}
