@import "../utils/colors";
@import "../utils/shadows";
@import "../utils/amounts";

@mixin defaultModal($height, $width) {
  position: absolute;
  background: $background-color;
  border-radius: $card-border-radius;

  width: $width;
  height: $height;
  top: 50%;
  left: 50%;
  margin-left: calc(-1 * $width / 2);
  margin-top: calc(-1 * $height / 2);

  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.15);
}

$overlay-color: darken($background-color, 60%);
$overlay-opacity: 0.2;

@keyframes darken {
  from {
    background-color: rgba($overlay-color, 0);
  }
  to {
    background-color: rgba($overlay-color, $overlay-opacity);
  }
}

@mixin defaultModalOverlay {
  position: fixed;
  z-index: 1000;
  inset: 0px;
  background-color: rgba($overlay-color, $overlay-opacity);
  animation-name: darken;
  animation-duration: 0.2s;
  z-index: 1000;
}
