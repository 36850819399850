@import "../utils/colors";

.institutionLogo {
  box-sizing: border-box;
  border-radius: 100%;
  border: 1px solid darken($background-color, 10%);
  flex-grow: 0;
  flex-shrink: 0;
}

.institutionLogoPlaceholder {
  display: flex;
  align-items: center;
  justify-content: center;
}