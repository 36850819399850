@import "../utils/colors";
@import "../utils/borders";
@import "../utils/amounts";

.mainContainer {
  width: 100%;
  padding: 20px 30px;
}

.topRow {
  display: flex;
  align-items: center;
}

.addBudgetButton {
  display: flex;
  align-items: center;
  background-color: white;
  border: 1px solid $default-border-color;
  border-radius: 7px;
  padding-right: 18px;
  padding-left: 13px;
  height: 40px;
  font-size: 14px;
  color: $dark-text-color;
}

.card {
  width: 100%;
  padding: 50px;
  background-color: white;
  border: $card-border;
  border-radius: $card-border-radius;
}

.headerBar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.leftHeader {
  display: flex;
  align-items: center;
}

.budgetName {
  font-size: 17px;
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.lineItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 12px;
}

.categoryName {
  white-space: pre !important;
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.formulaInput {
  padding: 5px 10px;
}
