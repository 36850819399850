@import "../utils/colors";
@import "../utils/DefaultModal.module.scss";
@import "../utils/borders";
@import "../utils/amounts";

.modalSmall {
  @include defaultModal(200px, 350px);
}

.modalLarge {
  @include defaultModal(500px, 800px);
}

.modalOverlay {
  @include defaultModalOverlay;
}

.contentContainer {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  padding: 35px;
}

.headerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  color: $dark-text-color;
  font-size: 18px;
  font-weight: 500;
}

.closeButton {
  color: $light-text-color;
}

.closeButton:hover {
  cursor: pointer;
  color: $dark-text-color;
}

.launchPlaidLinkButton {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid lighten($light-text-color, 40%);
  padding: 16px;
  height: 50px;
  color: $dark-text-color;
  background-color: $background-color;
}

.candidateAccountsCancellingContainer {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.candidateAccountsLoadingContainer {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: lighten($light-text-color, 20%);
}

.candidateAccountsContentContainer {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px 0px;
  align-items: flex-end;
}

.candidateAccountsContainer {
  width: 100%;
  padding: 10px;
  overflow: auto;
  border: $card-border;
  border-radius: $card-border-radius;
  color: white;
}

.candiateAccountContainer {
  display: flex;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid $default-border-color;
}

.candiateAccountContainer:last-child {
  border-bottom: none;
}

.candiateAccountInstitutionLogo {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  border: 1px solid darken($background-color, 10%);
}

.candiateAccountInstitutionLogoPlaceholder {
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  flex-grow: 0;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mutedLogo {
  opacity: 40%;
}

.candidateAccountInstitutionAndAccountNamesContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 150px;
}

@mixin candidateAccountTextField {
  width: 75px;
  color: $dark-text-color;
  overflow: hidden;
  text-overflow: ellipsis;
}

.candiateAccountInstitutionName {
  @include candidateAccountTextField;
}

.candiateAccountAccountName {
  @include candidateAccountTextField;
  width: 100px;
}

.candiateAccountAccountType {
  @include candidateAccountTextField;
}

.candiateAccountAccountMask {
  @include candidateAccountTextField;
  width: 50px;
}

.candiateAccountStartDateContainer {
  width: 170px;
}

.mutedText {
  color: lighten($light-text-color, 15%);
}

.candiateAccountCheckBox {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 23px;
  height: 23px;
  border-radius: 5px;
  border: 1px solid;
  margin-left: auto;
  border: 1px solid darken($background-color, 10%);
  background-color: darken($background-color, 3%);
  cursor: pointer;
}

.candiateAccountCheckBox:hover {
  background-color: darken($background-color, 10%);
}

.candcandiateAccountCheckBoxChecked,
.candcandiateAccountCheckBoxChecked:hover {
  border-color: $purple-accent-color;
  background-color: $purple-accent-color;
  color: $background-color;
}

.candcandiateAccountCheckBoxChecked:hover {
  border-color: lighten($purple-accent-color, 5%);
  background-color: lighten($purple-accent-color, 5%);
}

.bottomRowContainer {
  width: 100%;
  margin-top: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cancelButton {
  padding: 10px;
  background-color: $background-color;
  border: 1px solid darken($background-color, 15%);
  font-size: 14px;
  color: $light-text-color;
  font-weight: 500;
}
