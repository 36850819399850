.container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.dummyIcon {
  visibility: hidden;
}

@keyframes fadeInFromNone {
  0% {
    display: none;
    opacity: 0;
  }

  100% {
    display: block;
    opacity: 1;
  }
}

.breadLogo {
  width: 200px;
  animation: fadeInFromNone 0.5s ease-in;
}

.loadingBarContainer {
  height: 200px;
}
