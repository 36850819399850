@import "../utils/colors";
@import "../utils/shadows";
@import "../utils/amounts";
@import "../utils/DefaultModal.module.scss";

.modal {
  @include defaultModal(430px, 500px);
}

.modalOverlay {
  @include defaultModalOverlay;
}

.modalContainer {
  display: flex;
  height: 100%;
  flex-direction: column;
  padding: 35px;
}

.headerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 0;
}

.title {
  color: $dark-text-color;
  font-size: 18px;
  font-weight: 500;
}

.cancelButton {
  color: $light-text-color;
}

.cancelButton:hover {
  cursor: pointer;
  color: $dark-text-color;
}

.contentContainer {
  display: flex;
  height: 100%;
  flex-direction: column;
  flex: 1;
  justify-content: center;
}

.input {
  padding: 10px 20px;
  width: 100%;
  background-color: $background-color;
}

.deleteAccountSection {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border: 1px solid darken($background-color, 10%);
  border-radius: 5px;
}

.headerText {
  font-size: 15px;
  font-weight: 500;
  color: $dark-text-color;
}

.bodyText {
  color: $dark-text-color;
  font-size: 15px;
}

.bottomRowContainer {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  flex: 0;
}

.thinkingContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
