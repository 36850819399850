@import "../utils/colors";

.headerContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spacingContainer {
  width: 65%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nameAndLogo {
  display: flex;
  align-items: center;
  font-weight: 400;
  color: $purple-accent-color;
  font-size: 30px;
  cursor: pointer;
}

.logoImg {
  height: 55px;
  padding-right: 8px;
}

.signInButton {
  margin-right: 10px;
  padding: 9px 18px;
  background-color: white;
  border: 1px solid $default-border-color;
  border-radius: 100px;
  font-size: 15px;
  font-weight: 500;
  color: $dark-text-color;
}

.signInButton:hover {
  background-color: rgb(246, 246, 246);
  cursor: pointer;
}

.signUpButton {
  padding: 9px 18px;
  background-color: $purple-accent-color;
  border: none;
  border-radius: 100px;
  font-size: 15px;
  font-weight: 500;
  color: $background-color;
}

.signUpButton:hover {
  cursor: pointer;
  background-color: #6845db;
}

.mobileOnly {
  display: none;
}

@media only screen and (max-width: 1100px) {
  .desktopOnly {
    display: none;
  }

  .mobileOnly {
    display: block;
  }

  .spacingContainer {
    width: 80%;
  }

  .privacyRibbonContainer {
    justify-content: start;
    padding: 30px;
  }
}
