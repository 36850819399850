@tailwind base;
@tailwind components;
@tailwind utilities;

/*
  Set any "default" styles in here.

  - For documentation on the defaults that are already set by tailwind,
    https://tailwindcss.com/docs/preflight. 
    
  - For documentation on adding more defaults, see
    https://tailwindcss.com/docs/preflight#extending-preflight.
*/
@layer base {
  /* Note that Tailwind already makes border-box the default box-sizing so we don't
  have to do that explicitly (https://tailwindcss.com/docs/box-sizing#basic-usage) */

  html {
    @apply bg-base;
    @apply text-on-surface-700;
  }

  div,
  span,
  button,
  input {
    @apply text-md; /* we set this here because we don't want to actually change what the baseline 1rem is because that's what all other rem is calculated off of. This means we can't set html to text-md */
  }
}

@layer utilities {
  .clipped-shadow-right {
    box-shadow: 1px 0 2px rgba(0, 0, 0, 0.1);
    clip-path: inset(0 -15px 0 0);
  }

  .clipped-shadow-bottom {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    clip-path: inset(0 0 -15px 0);
  }
}
