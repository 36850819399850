@import "../utils/colors";

.amountContainer {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
}

.sign {
  margin-right: 2px;
}

.alignCenter {
  justify-content: center;
}

.alignLeft {
  justify-content: flex-start;
}
