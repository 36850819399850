$background-color: rgb(251, 251, 252);
$default-border-color: darken($background-color, 5%);

$purple-accent-color: #7d57ff;
$red-accent-color: rgb(226, 103, 122);
$green-accent-color: #3fd088;
$yellow-accent-color: #fecf21;

$lightest-text-color: #aeb0b2;
$light-text-color: #6b6f75;
$dark-text-color: #27282b;
