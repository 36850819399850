.inlineContainer {
  display: inline-flex;
  padding: 0;
  align-items: center;
}

.inlineTextbox {
  display: block;
  flex-direction: row;
  align-items: center;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}