@import "../utils/colors";

.merchantLogo {
  box-sizing: border-box;
  border-radius: 100%;
  border: 1px solid darken($background-color, 10%);
  flex-grow: 0;
  flex-shrink: 0;
  opacity: 80%;
}

.merchantLogoPlaceholder {
  display: flex;
  align-items: center;
  justify-content: center;
}
