@import "../utils/colors";

$very-light-text-color: #969aa0;
$bg-color: rgb(242, 242, 247);

.footerContainer {
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $bg-color;
  color: $very-light-text-color;
  font-weight: 500;
}

.privacyPolicyLink {
  color: $very-light-text-color;
  padding-bottom: 20px;
  cursor: pointer;
}

.privacyPolicyLink:hover {
  color: $purple-accent-color;
}
