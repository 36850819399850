@import "../utils/colors.scss";
@import "../utils/amounts.scss";

$fixed-width: 350px;

.authScreenContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background-color: $background-color;
}

$header-footer-height: 40vh;

.headerContainer {
  height: $header-footer-height;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.breadLogo {
  width: 100px;
}

.headerTitle {
  color: $purple-accent-color;
  font-weight: 400;
  font-size: 23px;
}

.footerFiller {
  height: $header-footer-height;
}

.authFormContainer {
  display: flex;
  flex-direction: column;
}

.authInput {
  border-radius: 5px;
  height: 50px;
  width: 350px;
  border: solid 1px rgb(179, 175, 182);
  padding: 0px 10px;
  font-size: 15px;
  background-color: transparent;

  &:focus {
    outline-color: $purple-accent-color;
  }
}

.accessCodeInput {
  text-align: center;
}

.authButton {
  border-radius: $button-border-radius;
  height: 50px;
  width: 350px;
  border: none;
  background-color: $purple-accent-color;
  color: white;
}

.signInOptionButton {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $background-color;
  $color: $light-text-color;
  border: 1px solid $color;
  color: $color;
}

.googleLogo {
  width: 17px;
}

.authScreenSwitchTextContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: transparent;
  border: none;
  color: $light-text-color;
  font-size: 15px;
}

.authScreenSwitchTextButton {
  color: $purple-accent-color;
  text-decoration: underline;
}

.authScreenSwitchTextButton:hover {
  cursor: pointer;
  color: lighten($purple-accent-color, 5%);
}

.errorMsgBanner {
  width: $fixed-width;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  border: 1px solid $red-accent-color;
  color: $red-accent-color;
  background-color: lighten($red-accent-color, 32%);
  border-radius: $button-border-radius;
  white-space: pre-wrap;
  text-align: center;
}
