@import "../utils/colors";
@import "../utils/shadows";
@import "../utils/amounts";

.modal {
  position: absolute;
  background: white;
  border-radius: $card-border-radius;
  top: 50%;
  left: 50%;
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.15);
}

$overlay-color: darken($background-color, 40%);
$overlay-opacity: 0.2;

@keyframes darken {
  from {
    background-color: rgba($overlay-color, 0);
  }
  to {
    background-color: rgba($overlay-color, $overlay-opacity);
  }
}

.modalOverlay {
  position: fixed;
  z-index: 1000;
  inset: 0px;
  background-color: rgba($overlay-color, $overlay-opacity);
  animation-name: darken;
  animation-duration: 0.2s;
  overflow: auto;
}
