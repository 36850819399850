.EmojiPickerReact {
  --epr-emoji-size: 23px;
  --epr-category-navigation-button-size: 23px;
  --epr-header-padding: 7px 10px;
  --epr-picker-border-color: rgb(255, 255, 255);
  font-size: 10px;
  position: absolute !important;
  top: 2.1rem;
  left: 0rem;
  z-index: 100;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
}
