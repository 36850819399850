@import "../utils/colors";
@import "../utils/amounts";

// You probably don't want to use .baseButton directly,
// the recommendation is to create a new class that extends it.
.baseButton {
  padding: 10px 15px;
  border-radius: $button-border-radius;
  border: none;
  margin-left: auto;
  font-weight: bolder;
}

.baseButton:hover {
  padding: 9px 14px;
  border-width: 1px;
  border-style: solid;
}

.saveButton {
  @extend .baseButton;
  color: $background-color;
  background: $purple-accent-color;
}

.saveButton:hover {
  @extend .baseButton;
  color: $purple-accent-color;
  background: $background-color;
}

.dangerousButton {
  @extend .baseButton;
  color: $background-color;
  background: darken($red-accent-color, 15%);
}

.dangerousButton:hover {
  color: darken($red-accent-color, 15%);
  border-color: darken($red-accent-color, 15%);
  background: $background-color;
}


.cancelButton {
  @extend .baseButton;
  color: $background-color;
  background: $light-text-color;
}

.cancelButton:hover {
  @extend .baseButton;
  color: $light-text-color;
  background: $background-color;
}